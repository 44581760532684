import React, { ReactElement } from 'react'
import AboutUsLayout from '../../components/Layout/AboutUs'
import './PrivacyNotice.scss'
import SEO from '../../components/SEO/SEO'
import Content from '../../components/Content'
import ContentNav from '../../components/ContentNav'

import PrivacyImage from '../../assets/icons/properties/alisi.png'

import { graphql, useStaticQuery } from 'gatsby'

const links = [
  {
    name: 'Privacy Notice',
    url: '/privacy-notice'
  },
  {
    name: 'Terms and Conditions',
    url: '/terms-and-conditions'
  }
]

const PrivacyPolicy = (): ReactElement => {
  const privactPolicyData = useStaticQuery(graphql`
    query {
      privacyPolicy {
        id
        name
        containers {
          name
          field_collections {
            name
            fields {
              name
              value
            }
          }
        }
      }
      heroBanner(title: { eq: "Terms" }) {
        image
      }
    }
  `)

  const termsAndConditions = privactPolicyData.privacyPolicy.containers[0].field_collections[0].fields

  const title = termsAndConditions ? termsAndConditions[0].value : ''
  const body = termsAndConditions && termsAndConditions[1] ? termsAndConditions[1].value : ''

  return (
    <AboutUsLayout heroImage={privactPolicyData.heroBanner ? privactPolicyData.heroBanner.image : ''}>
      <SEO title="Privacy Notice - ALISI | Ayala Land" />
      <ContentNav links={links} active={0} />
      <Content>
        <h1>{title}</h1>

        <div className="privacy-notice">
          <div
            className="privacy-notice-body"
            dangerouslySetInnerHTML={{
              __html: body
            }}
          />
          <img className="privacy-notice-image" src={PrivacyImage} width={300} />
        </div>
      </Content>
    </AboutUsLayout>
  )
}

export default PrivacyPolicy
